import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { selectColor, selectFont } from "../../../utils/themeUtils";
import type { ErrorMessageMapping } from "../lib/errorUtils";
import { mapErrorMessageAndLog } from "../lib/errorUtils";
import { useTranslation } from "../../../hooks/useTranslation";

export const useInlineError = () => {
  const previousError = useRef(null);
  const [inlineErrorElement, setInlineErrorElement] =
    useState<React.ReactElement>();
  const hideInlineError = useCallback(() => {
    setInlineErrorElement(null);
    previousError.current = null;
  }, []);
  const showInlineErrorAndLog = useCallback(
    (error: object, messageMappings?: ErrorMessageMapping[]) => {
      if (error !== previousError.current) {
        const { logCode, mapping } = mapErrorMessageAndLog(
          error,
          messageMappings,
        );
        setInlineErrorElement(
          <InlineError logCode={logCode} mapping={mapping} />,
        );

        previousError.current = error;
      }
    },
    [],
  );

  return { inlineErrorElement, hideInlineError, showInlineErrorAndLog };
};

const Base = styled.div`
  ${selectFont("inlineError")}
  display: flex;
  flex-direction: column;
  background-color: ${selectColor("redLight")};
  color: ${selectColor("veloplusRot")};
  padding: 14px 14px;
  border-radius: 5px;
`;

const LogCode = styled.div`
  margin-top: 8px;
`;

export interface InlineErrorProps {
  style?: React.CSSProperties;
  className?: string;
  logCode?: string;
  mapping?: ErrorMessageMapping;
}

const InlineError: React.FC<InlineErrorProps> = ({ logCode, mapping }) => {
  if (!process.browser) {
    return;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t: tCommon } = useTranslation("common");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t: tNamespace } = useTranslation(mapping?.ns);

  const message = mapping
    ? tNamespace(mapping.key)
    : tCommon("genericErrorMessage");

  return (
    <Base>
      {message}
      {logCode ? <LogCode>(Log Code: {logCode})</LogCode> : null}
    </Base>
  );
};
