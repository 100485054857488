import styled, { css } from "styled-components";

import { selectFont, selectZIndex } from "../../../utils/themeUtils";

export const inputStyle = css`
  ${selectFont("input")};
  z-index: ${selectZIndex("form")};
  line-height: 28px;
  background: none;
  width: 100%;
  display: block;
  border: none;
  padding: 4px 8px;
  margin: 2px;
  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;
const Input = styled.input.attrs(({ value }) => {
  return { value: value === null ? "" : value };
})`
  ${inputStyle};
`;
Input.defaultProps = {
  type: "text",
  value: "",
};

export default Input;
