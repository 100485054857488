import { generateLogCode } from "./stringUtils";

export interface ErrorMessageMapping {
  match: RegExp | string;
  ns: string;
  key: string;
  showLogCode: boolean;
}

const baseNs = "common";
const baseMessageMappings: ErrorMessageMapping[] = [
  {
    match: /unrecognized options for login request/i,
    ns: baseNs,
    key: "login.unrecognizedOptions",
    showLogCode: true,
  },
  {
    match: /match failed/i,
    ns: baseNs,
    key: "login.matchFailed",
    showLogCode: true,
  },
  {
    match: /user has no password set/i,
    ns: baseNs,
    key: "login.noPasswordSet",
    showLogCode: false,
  },
  {
    match: /Invalid credentials/i,
    ns: baseNs,
    key: "login.userOrPasswordWrong",
    showLogCode: false,
  },

  {
    match: /username already exists/i,
    ns: baseNs,
    key: "registration.alreadyExists",
    showLogCode: false,
  },
  {
    match: /email already exists/i,
    ns: baseNs,
    key: "registration.alreadyExists",
    showLogCode: false,
  },
  {
    match: /email address is invalid/i,
    ns: baseNs,
    key: "form.fields.email.errorMessage",
    showLogCode: false,
  },
  {
    match: /captcha verification failed/i,
    ns: baseNs,
    key: "captcha.verificationError",
    showLogCode: true,
  },
  {
    match: "captcha-expired",
    ns: baseNs,
    key: "captcha.expired",
    showLogCode: false,
  },
  {
    match: "captcha-error",
    ns: baseNs,
    key: "captcha.error",
    showLogCode: false,
  },
  {
    match: /file exceeds the size limit/,
    ns: baseNs,
    key: "upload.exceedsSize",
    showLogCode: false,
  },
  {
    match: /file did not match any allowed mime types/,
    ns: baseNs,
    key: "upload.invalidMimeType",
    showLogCode: false,
  },
  {
    match: /link expired/,
    ns: baseNs,
    key: "verificationLinkExpired",
    showLogCode: false,
  },
];

const findMessageMapping = (
  originalMessage: string,
  mappings: ErrorMessageMapping[],
): ErrorMessageMapping | undefined =>
  mappings.find(({ match, ns, key }) => {
    if (match instanceof RegExp) {
      return match.test(originalMessage);
    } else {
      return match === originalMessage;
    }
  });

const mapErrorMessageAndLog = (
  error: object,
  messageMappings?: ErrorMessageMapping[],
) => {
  const errorMessage = isObject(error) ? (error as any).message : error;
  error = ensureErrorType(error);

  const mappings = messageMappings
    ? baseMessageMappings.concat(messageMappings)
    : baseMessageMappings;
  const mapping = findMessageMapping(errorMessage, mappings);

  const addLogCode = (!mapping || mapping.showLogCode) && !getLogCode(error);
  if (addLogCode) {
    error = addLogCodeToError(error);
  }
  const logCode = getLogCode(error);
  import("@sentry/react").then((e) => e.captureException(error));

  if (process.env.NODE_ENV === "development") {
    if (logCode) {
      // tslint:disable-next-line: no-console
      console.error(`[logCode: ${logCode}]`, error);
    } else {
      // tslint:disable-next-line: no-console
      console.error(error);
    }
  }

  return {
    mapping,
    logCode,
  };
};

function isObject(value: any): value is object {
  return typeof value === "object" && value !== null;
}

function ensureErrorType(exception: unknown): Error {
  if (exception instanceof Error) {
    return exception;
  }

  let message = JSON.stringify(exception);
  message = `wrapped error: ${message}`;

  const error = new Error(message);

  if (isObject(exception)) {
    (error as any).logCode = (exception as any).logCode;
  }

  return error;
}

function flagErrorAsTracked(exception: unknown): Error {
  const error: any = ensureErrorType(exception);
  error.isTrackedBySentry = true;
  return error;
}

function isErrorAlreadyTracked(error: unknown) {
  if (!(error instanceof Error)) {
    return false;
  }

  return (error as any).isTrackedBySentry === true;
}

function addLogCodeToError(exception: unknown): Error {
  const error: any = ensureErrorType(exception);

  if (!error.logCode) {
    error.logCode = generateLogCode();
  }

  return error;
}

function getLogCode(error: unknown) {
  if (!isObject(error)) {
    return null;
  }

  return (error as any).logCode;
}

export {
  mapErrorMessageAndLog,
  flagErrorAsTracked,
  isErrorAlreadyTracked,
  addLogCodeToError,
  getLogCode,
};
