import type { FC } from "react";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Input from "../../../form/components/Input";
import { iff, selectColor, selectZIndex } from "../../../../utils/themeUtils";
import { useTranslation } from "../../../../hooks/useTranslation";
import MailIcon from "../../../icons/components/MailIcon";

import ProcessingIndicator from "../../../layout/components/ProcessingIndicator";
import { useInlineError } from "../../../core/components/InlineError";
import { useRouter } from "next/router";
import { NEWSLETTER_CONFIRMATION_PATH } from "../../../../config/locations";

import { gql, useMutation } from "@apollo/client";
import type {
  SubscribeNewsletter,
  SubscribeNewsletterVariables,
} from "./types/SubscribeNewsletter";
import type { CellPluginComponentProps } from "@react-page/editor";

const size = 40;
const borderRadius = 5;
const buttonOffset = Math.round(size / 2);

const Base = styled.div<{ align: Align }>`
  ${iff((p) => p.align !== "inherit")`
      display: flex;
      justify-content: ${(p) =>
        p.align === "left"
          ? "flex-start"
          : p.align === "center"
          ? "center"
          : "flex-end"};
  `}
`;

const Form = styled.form`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 400px;
`;

const InputWrapper = styled.div`
  display: inline-flex;
  position: relative;
`;

const StyledInput = styled(Input)`
  position: relative;
  background: ${selectColor("white")};
  border-radius: ${borderRadius}px;
  padding: 0 ${size}px 0 15px;
  width: calc(100% - ${buttonOffset}px);
  height: ${size}px;
  font-size: 14px;

  &::placeholder {
    color: ${selectColor("black")};
    opacity: 1;
  }
`;

const Button = styled.button<{ disabled: boolean }>`
  position: absolute;
  right: 0;
  top: 2px;
  z-index: ${selectZIndex("form")};
  padding: 0;
  margin: 0;
  width: ${size}px;
  height: ${size}px;
  border-radius: 50%;
  border: none;
  background-color: ${selectColor("petrol")};
  &:hover {
    background-color: ${selectColor("petrol")};
  }
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: none;

  ${iff((p) => !p.disabled)`
      cursor: pointer;
  `}
`;

const ErrorWrapper = styled.div`
  margin-top: 20px;
`;

const SUBSCRIBE_TO_NEWSLETTER = gql`
  mutation SubscribeNewsletter($email: String!) {
    subscribeNewsletter(input: { email: $email }) {
      success
    }
  }
`;

export type Align = "left" | "center" | "right" | "inherit";
export type Data = {
  align?: Align;
};

const Component: FC<CellPluginComponentProps<Data>> = ({ data }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [processing, setProcessing] = useState(false);
  const [subscribeNewsletter] = useMutation<
    SubscribeNewsletter,
    SubscribeNewsletterVariables
  >(SUBSCRIBE_TO_NEWSLETTER);
  const handleEmailChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);
  const { inlineErrorElement, showInlineErrorAndLog, hideInlineError } =
    useInlineError();

  return (
    <Base align={data.align}>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();

          if (!email.trim() || processing) {
            return;
          }

          hideInlineError();
          setProcessing(true);

          try {
            await subscribeNewsletter({ variables: { email } });
            router.push(NEWSLETTER_CONFIRMATION_PATH);
          } catch (err) {
            showInlineErrorAndLog(err);
            setProcessing(false);
          }
        }}
      >
        <InputWrapper>
          <StyledInput
            type="email"
            placeholder={t("newsletterPlaceholder")}
            value={email}
            onChange={handleEmailChange}
          />
          <Button disabled={processing}>
            {processing ? (
              <ProcessingIndicator color="white" size={18} />
            ) : (
              <MailIcon />
            )}
          </Button>
        </InputWrapper>
        {inlineErrorElement ? (
          <ErrorWrapper>{inlineErrorElement}</ErrorWrapper>
        ) : null}
      </Form>
    </Base>
  );
};

export default Component;
