import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  width: 19px;
  height: 15px;
  path,
  mask {
    fill: ${selectColor((p) => p.color)};
  }
`;

export interface MailIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: any;
}

const MailIcon: React.FC<MailIconProps> = ({
  style,
  className,
  color = "white",
}) => (
  <SVG viewBox="0 0 19 15" style={style} className={className} color={color}>
    <defs>
      <path id="a" d="M0 0h19v15H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b">
        <use xlinkHref="#a" />
      </mask>
      <path
        d="M9.5 6.5625L1.9 1.875h15.2L9.5 6.5625zm6.65 6.5625H2.85c-.5225 0-.95-.42188-.95-.9375V3.75l6.593 4.06875c.6175.38438 1.3965.38438 2.014 0L17.1 3.75v8.4375c0 .51563-.4275.9375-.95.9375zM17.1 0H1.9C.855 0 .0095.84375.0095 1.875L0 13.125C0 14.15625.855 15 1.9 15h15.2c1.045 0 1.9-.84375 1.9-1.875V1.875C19 .84375 18.145 0 17.1 0z"
        mask="url(#b)"
      />
    </g>
  </SVG>
);

export default MailIcon;
